














import { Component, Vue, Prop } from 'vue-property-decorator'

import ZonePickerModalOptions from '@/app_code/Modals/Options/ZonePickerModalOptions'
import { DropdownListItem } from '@/app_code/Forms'
import { Services } from 'truemarket-modules/src/services'
import { ZoneModel } from 'truemarket-modules/src/models/api/admin'

@Component
export default class ZonePickerModal extends Vue {
  @Prop()
  private options!: ZonePickerModalOptions;

  private countryOptions: DropdownListItem[] = []

  private zoneOptions: DropdownListItem[] = []

  private zones: ZoneModel[] = []

  private zoneId = ''

  mounted () {
    Services.API.Admin.Countries.GetCountries().then((countries) => {
      this.countryOptions = [new DropdownListItem('', 'Select a Country'), ...countries.map((c) => new DropdownListItem(c.CountryId, c.Name))]
    })
  }

  countryPicked (countryId: string) {
    if (!countryId) return

    Services.API.Admin.Countries.GetZones(countryId).then((zones) => {
      this.zones = zones

      this.zoneOptions = [new DropdownListItem('', 'Select a Zone'), ...zones.map((z) => new DropdownListItem(z.ZoneId, z.TMName))]
    })
  }

  pickZone () {
    const zone = this.zones.find((z) => z.ZoneId === this.zoneId)

    if (this.options.OnSelected && zone) this.options.OnSelected(zone)

    this.$emit('close')
  }
}
